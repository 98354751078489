<template>
    <div class="clientcabin clientcabin-blog">
        <v-container class="black--text">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg mb-4"
                        src="@/assets/img/clientcabin/stories/content-amplification-strategy/1.png"
                        width="100%"
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        Using Content Amplification Strategies To Drive Leads &
                        Conversions For NYC Commercial Spaces
                    </p>
                    <p>
                        According to marketers,
                        <a
                            href="https://www.redevolution.com/blog/the-challenge-of-getting-website-traffic-and-leads"
                            target="_blank"
                        >
                            generating traffic
                        </a>
                        is the top problem for businesses today. Businesses
                        struggle with understanding their audiences and
                        explaining their products and services.
                    </p>
                    <p>
                        Many small and medium-sized companies and real estate
                        agencies do not have the time and resources to find out
                        what their audiences search for online. They also find
                        it difficult to track reviews and post comments about
                        them and their content.
                    </p>
                    <p>
                        Ultimately, many businesses do not have the right tools
                        to generate traffic and sales. If you find yourself
                        drowning in a demanding content strategy, you can turn
                        to new resources to boost your business – content
                        amplification.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Content Amplification - How To Get Your Brand Noticed &
                        Generate High-Converting Leads
                    </p>
                    <p class="font-weight-bold">
                        Content amplification allows your content to reach a
                        much wider audience. You can think of it as amplifying
                        the sound of your message, brand, product, or service.
                    </p>
                    <p>
                        Content amplification is a
                        <a
                            href="https://blog.hubspot.com/marketing/content-amplification?__hstc=140496364.f99ab8732bd3540a5dc323212bc09330.1726474173887.1727084513901.1727087405069.15&__hssc=140496364.1.1727087405069&__hsfp=3835108981"
                            target="_blank"
                        >
                            method that uses online platforms
                        </a>
                        to promote business content. It relies on several
                        strategies to help your business succeed in an
                        increasingly competitive online environment.
                    </p>
                    <p>
                        For example, you could use an infographic or audio ad
                        campaign. You could create a news release or
                        video-driven content. You could target your audience and
                        make connections with industry influencers more
                        effectively.
                    </p>
                    <p>
                        Your company can then build a stronger online presence.
                        You can build long-term brand exposure and improve
                        company recognition.
                    </p>
                    <p>
                        Content amplification can drive business traffic and
                        sales and improve your marketing strategy.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        The Art Of Amplifying Your Brand Voice To Reach Large
                        Audiences On Major Digital Platforms And Websites
                    </p>
                    <p>
                        {{ company }} uses one of the first content
                        amplification strategies that allows companies to more
                        effectively reach their customers.
                    </p>
                    <p>
                        We manage the production and distribution of tailored
                        content to drive business connections in this age of
                        online competition.
                    </p>
                    <p>
                        How does it work? {{ company }} creates strategic
                        content for your company and optimizes its placement.
                        For example, {{ company }} places brands on the largest
                        traffic news sites. These sites include Yahoo!
                        StreetInsider, Associated Press, Bloomberg, NBC
                        Affiliate sites and more.
                    </p>
                    <p>
                        {{ company }} focuses on providing online visibility for
                        your business by channeling tailored content through
                        digital placements and websites. For example,
                        {{ company }} transforms an article into a news release,
                        video, or blog post, and then place this content on
                        prominent sites, such as Google News, YouTube, and Apple
                        Podcasts.
                    </p>
                    <p>
                        {{ company }} creates, refines, and distributes six
                        content types. This content is then sent out to hundreds
                        of digital platforms and websites. Each unique campaign
                        has at least one news article, blog post, video,
                        Infographic, and audio advertisement.
                    </p>
                    <p>
                        {{ company }} uses an extremely efficient content
                        marketing strategy - and we have the results to prove
                        it.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        This Real Estate Agency's Content Is Holding A Featured
                        Snippet on Google for 6 Months and Counting!
                    </p>
                    <p>
                        Let’s have a look at a prime example of {{ company }}’s
                        effectiveness and how {{ company }}’s content marketing
                        campaigns with premium distribution helped a real estate
                        agency build a stronger, more powerful presence in the
                        virtual office market.
                    </p>
                    <p>
                        One of our long-time users was looking for a way to
                        generate traffic for a NY-based real estate agency and
                        promote their office spaces. After working with us to
                        develop content marketing campaigns that were optimized
                        for premium distribution, he saw his client’s business
                        get top results on Google, Yahoo and Bing.
                    </p>
                    <p>
                        One of our articles is currently being used as a
                        “featured snippet” (NB: USA Google.com search engine) by
                        Google—and it’s been there for over 6 months! Our
                        content marketing campaigns are still holding this top
                        spot.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/content-amplification-strategy/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Our client knew from his experience working with other
                        clients that our campaigns were a great way to generate
                        more traffic and leads for real estate agencies.
                    </p>
                    <p>
                        One of the biggest benefits of working with
                        {{ company }} is our understanding of how to get our
                        clients’ content onto high-ranking pages on Google. Our
                        team knows what kind of content will be effective and
                        how to distribute it effectively as well. Our client has
                        seen a bump in traffic every month since he began using
                        {{ company }}, plus upward trending traffic, higher
                        highs, and higher lows. He can also expect more
                        campaigns every month as his business continues to grow.
                        His goal was to continue with the same campaign but
                        increase the number of articles produced each month.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/content-amplification-strategy/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        You work with {{ company }}, you're guaranteed to get
                        more views — and more traffic from those views!
                    </p>
                    <p>
                        Our writers created an integrated campaign that included
                        premium distribution of content across multiple
                        channels.
                    </p>
                    <v-container class="pa-0">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-img
                                    class="rounded-lg"
                                    src="@/assets/img/clientcabin/stories/content-amplification-strategy/4.png"
                                    width="100%"
                                />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-img
                                    class="rounded-lg"
                                    src="@/assets/img/clientcabin/stories/content-amplification-strategy/5.png"
                                    width="100%"
                                />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-img
                                    class="rounded-lg"
                                    src="@/assets/img/clientcabin/stories/content-amplification-strategy/6.png"
                                    width="100%"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                    <p class="text-h4 mt-8 text-center">
                        Massive Online Visibility For The NY Agency's Mobile
                        Workspaces
                    </p>
                    <p>
                        This campaign was distributed to more than 400
                        high-traffic sites which resulted in the real estate
                        having an article about its mobile working environments
                        that holds a top spot in Google and Yahoo searches since
                        November 2021.
                    </p>
                    <p>
                        This exposure leads potential clients to learn more
                        about the company and its services.
                    </p>
                    <p>
                        The results indicate that the real estate agency has
                        seen increases in traffic and upward trending traffic.
                        These positive results were secured using a campaign
                        released in a $15-$20 per click market.
                    </p>
                    <v-img
                        class="rounded-lg mb-4"
                        src="@/assets/img/clientcabin/stories/content-amplification-strategy/7.png"
                        width="100%"
                    />
                    <p>
                        All of this resulted in our client immediately securing
                        new business.
                    </p>
                    <p>
                        The client was very happy with the results and has
                        decided to continue working with us for ongoing content
                        marketing campaigns.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Enjoy A Massive Leap Forward & Expand Your Online
                        Presence With {{ company }}
                    </p>
                    <p>
                        {{ company }}’s content amplification platform can help
                        your company get the exposure it needs to succeed. Our
                        client’s story is a great case study for how this works.
                        They used {{ company }} premium campaigns to get their
                        message out there, and it paid off with an increase in
                        traffic and sales.
                    </p>
                    <p>
                        Your company could also benefit from this kind of
                        exposure and generate traffic and sales by
                        <router-link to="/info" class="font-weight-bold">
                            booking an appointment
                        </router-link>
                        with us today!
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class ContentAmplificationStrategy extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-blog::v-deep {
    background-color: $white;
    color: $black;

    a {
        display: inline-block;
    }
}
</style>
